<template>
  <div class="row">
    <div class="col-12 col-lg-3">
        <div class="card card-body text-center">
            <h1>
                {{ stats.projects }}
            </h1>
            اجمالي المخططات
        </div>
    </div>
    <div class="col-12 col-lg-3">
        <div class="card card-body text-center">
            <h1>
                {{ stats.selected_noti }}
            </h1>
            الخططات المختارة للتنبيهات
        </div>
    </div>
    <div class="col-12 col-lg-3">
        <div class="card card-body text-center">
            <h1>
                {{ stats.selected_book }}
            </h1>
            الخططات المختارة للحجز
        </div>
    </div>
    <div class="col-12 col-lg-3">
        <div class="card card-body text-center">
            <h1>
                {{ stats.today_noti }}
            </h1>
            التنبيهات اليوم
        </div>
    </div>
    <div class="col-12 col-lg-3">
        <div class="card card-body text-center">
            <h1>
                {{ stats.today_book }}
            </h1>
            الحجوزات اليوم
        </div>
    </div>
    <div class="col-12 col-lg-3">
        <div class="card card-body text-center">
            <h1>
                {{ stats.all_book }}
            </h1>
            اجمالي الحجوزات
        </div>
    </div>
    <div class="col-12 col-lg-3">
        <div class="card card-body text-center">
            <h1>
                {{ stats.all_noti }}
            </h1>
            اجمالي التنبيهات
        </div>
    </div>
    <div class="col-12 col-lg-3">
        <div class="card card-body text-center">
            <h1>
                {{ stats.last_update }}
            </h1>
            آخر تحديث للبيانات
        </div>
    </div>
    <div class="col-12 g">
        <div class="card">
            <div class="card-header">
                <h4>
                    <i class="fa fa-fire"></i> سجل التغييرات
                </h4>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm table-striped">
                        <thead>
                            <th>
                                المخطط
                            </th>
                            <th>
                                الكل الآن
                            </th>
                            <th>
                                الكل سابقاً
                            </th>
                            <th>
                                المتاح الآن
                            </th>
                            <th>
                                المتاح سابقاً
                            </th>
                            <th>
                                متاح للحجز
                            </th>
                            <th>
                                التوقيت
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="log in logs" :key="log._id" :class="log.avilable_after > log.avilable_before ? 'bg-light-success' : (
                                log.avilable_after < log.avilable_before ? 'bg-light-danger' : ''
                                )">
                                <td>
                                    <span v-html="log.avilable_after > log.avilable_before ? `<i class='fa fa-arrow-up text-success'></i>` : (
                                log.avilable_after < log.avilable_before ? `<i class='fa fa-arrow-down text-danger'></i>` : ''
                                )"></span>
                                ({{ log.item_id }})
                                    {{ projects[log.item_id].name }}
                                </td>
                                <td>
                                    {{ log.all_after }}
                                </td>
                                <td>
                                    {{ log.all_before }}
                                </td>
                                <td>
                                    {{ log.avilable_after }}
                                </td>
                                <td>
                                    {{ log.avilable_before }}
                                </td>
                                <td>
                                    <span v-if="log.bookable" class="text-success">
                                    <i class="fa fa-check-square"></i>
                                    </span>
                                    <span v-if="!log.bookable" class="text-danger">
                                        <i class="fa fa-times"></i>
                                    </span>
                                </td>
                                <td>
                                    {{ log.date }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            logs: [],
            user: JSON.parse(localStorage.getItem("user")),
            projects: {},
            stats: {}
        }
    },
    created(){
        var g = this;
        $.post(api + '/user/projects/all', {
            jwt: g.user.jwt
        }).then(function(r){
            r.response.forEach(function(a){
                g.projects[a.item_id] = a
            })
            g.getLogs()
        })
        g.sockets.subscribe("noti", function(data){
            g.getLogs()
        })
        $.post(api + '/user/projects/stats', {
            jwt: g.user.jwt
        }).then(function(r){
            g.stats = r.response
        })
    },
    methods: {
        getLogs(){
            var g = this;
            $.post(api + '/user/projects/logs', {
                jwt: g.user.jwt
            }).then(function(r){
                g.logs = r.response
            })
        }
    }
}
</script>

<style>

</style>